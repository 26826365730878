
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {},
  "una": {
    "primary": "yellow",
    "gray": "stone",
    "radius": 0.5,
    "fontSize": 16
  },
  "env": "release",
  "buildInfo": {
    "version": "2.0.0-1728033549.63e1e70",
    "time": 1728033621819,
    "commit": "035d783e118e639734ab08ae006ff4767ba6f1a2",
    "shortCommit": "035d783",
    "branch": "release",
    "env": "release"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
