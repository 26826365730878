import { defineNuxtPlugin } from '#app/nuxt'
import { LazyGAccordion, LazyGAlert, LazyGAvatar, LazyGAvatarGroup, LazyGBadge, LazyGButton, LazyGIcon, LazyGIndicator, LazyGKbd, LazyGLabel, LazyGLink, LazyGProgress, LazyGSeparator, LazyGSkeleton, LazyGCard, LazyGCardAbout, LazyGCardContent, LazyGCardDescription, LazyGCardFooter, LazyGCardHeader, LazyGCardTitle, LazyGDialog, LazyGDialogClose, LazyGDialogContent, LazyGDialogDescription, LazyGDialogFooter, LazyGDialogHeader, LazyGDialogOverlay, LazyGDialogScrollContent, LazyGDialogTitle, LazyGDropdownMenu, LazyGDropdownMenuContent, LazyGDropdownMenuGroup, LazyGDropdownMenuItem, LazyGDropdownMenuLabel, LazyGDropdownMenuRoot, LazyGDropdownMenuSeparator, LazyGDropdownMenuShortcut, LazyGDropdownMenuSub, LazyGDropdownMenuSubContent, LazyGDropdownMenuSubTrigger, LazyGDropdownMenuTrigger, LazyGPagination, LazyGPaginationEllipsis, LazyGPaginationFirst, LazyGPaginationLast, LazyGPaginationListItem, LazyGPaginationNext, LazyGPaginationPrev, LazyGPopover, LazyGPopoverContent, LazyGTabs, LazyGTabsContent, LazyGTabsList, LazyGTabsRoot, LazyGTabsTrigger, LazyGTooltip, LazyGTooltipContent, LazyGTooltipProvider, LazyGTooltipRoot, LazyGTooltipTrigger, LazyGCheckbox, LazyGFormGroup, LazyGInput, LazyGRadio, LazyGSlider, LazyGSwitch, LazyGSelect, LazyGSelectContent, LazyGSelectGroup, LazyGSelectItem, LazyGSelectItemIndicator, LazyGSelectItemText, LazyGSelectLabel, LazyGSelectRoot, LazyGSelectScrollDownButton, LazyGSelectScrollUpButton, LazyGSelectSeparator, LazyGSelectTrigger, LazyGSelectValue, LazyGThemeSwitcher, LazyGNavLink, LazyGNavLinkGroup, LazyGBreadcrumb, LazyGBreadcrumbEllipsis, LazyGBreadcrumbItem, LazyGBreadcrumbLink, LazyGBreadcrumbList, LazyGBreadcrumbRoot, LazyGBreadcrumbSeparator, LazyGTable, LazyGTableBody, LazyGTableCaption, LazyGTableCell, LazyGTableEmpty, LazyGTableFooter, LazyGTableHead, LazyGTableHeader, LazyGTableLoading, LazyGTableRoot, LazyGTableRow } from '#components'
const lazyGlobalComponents = [
  ["GAccordion", LazyGAccordion],
["GAlert", LazyGAlert],
["GAvatar", LazyGAvatar],
["GAvatarGroup", LazyGAvatarGroup],
["GBadge", LazyGBadge],
["GButton", LazyGButton],
["GIcon", LazyGIcon],
["GIndicator", LazyGIndicator],
["GKbd", LazyGKbd],
["GLabel", LazyGLabel],
["GLink", LazyGLink],
["GProgress", LazyGProgress],
["GSeparator", LazyGSeparator],
["GSkeleton", LazyGSkeleton],
["GCard", LazyGCard],
["GCardAbout", LazyGCardAbout],
["GCardContent", LazyGCardContent],
["GCardDescription", LazyGCardDescription],
["GCardFooter", LazyGCardFooter],
["GCardHeader", LazyGCardHeader],
["GCardTitle", LazyGCardTitle],
["GDialog", LazyGDialog],
["GDialogClose", LazyGDialogClose],
["GDialogContent", LazyGDialogContent],
["GDialogDescription", LazyGDialogDescription],
["GDialogFooter", LazyGDialogFooter],
["GDialogHeader", LazyGDialogHeader],
["GDialogOverlay", LazyGDialogOverlay],
["GDialogScrollContent", LazyGDialogScrollContent],
["GDialogTitle", LazyGDialogTitle],
["GDropdownMenu", LazyGDropdownMenu],
["GDropdownMenuContent", LazyGDropdownMenuContent],
["GDropdownMenuGroup", LazyGDropdownMenuGroup],
["GDropdownMenuItem", LazyGDropdownMenuItem],
["GDropdownMenuLabel", LazyGDropdownMenuLabel],
["GDropdownMenuRoot", LazyGDropdownMenuRoot],
["GDropdownMenuSeparator", LazyGDropdownMenuSeparator],
["GDropdownMenuShortcut", LazyGDropdownMenuShortcut],
["GDropdownMenuSub", LazyGDropdownMenuSub],
["GDropdownMenuSubContent", LazyGDropdownMenuSubContent],
["GDropdownMenuSubTrigger", LazyGDropdownMenuSubTrigger],
["GDropdownMenuTrigger", LazyGDropdownMenuTrigger],
["GPagination", LazyGPagination],
["GPaginationEllipsis", LazyGPaginationEllipsis],
["GPaginationFirst", LazyGPaginationFirst],
["GPaginationLast", LazyGPaginationLast],
["GPaginationListItem", LazyGPaginationListItem],
["GPaginationNext", LazyGPaginationNext],
["GPaginationPrev", LazyGPaginationPrev],
["GPopover", LazyGPopover],
["GPopoverContent", LazyGPopoverContent],
["GTabs", LazyGTabs],
["GTabsContent", LazyGTabsContent],
["GTabsList", LazyGTabsList],
["GTabsRoot", LazyGTabsRoot],
["GTabsTrigger", LazyGTabsTrigger],
["GTooltip", LazyGTooltip],
["GTooltipContent", LazyGTooltipContent],
["GTooltipProvider", LazyGTooltipProvider],
["GTooltipRoot", LazyGTooltipRoot],
["GTooltipTrigger", LazyGTooltipTrigger],
["GCheckbox", LazyGCheckbox],
["GFormGroup", LazyGFormGroup],
["GInput", LazyGInput],
["GRadio", LazyGRadio],
["GSlider", LazyGSlider],
["GSwitch", LazyGSwitch],
["GSelect", LazyGSelect],
["GSelectContent", LazyGSelectContent],
["GSelectGroup", LazyGSelectGroup],
["GSelectItem", LazyGSelectItem],
["GSelectItemIndicator", LazyGSelectItemIndicator],
["GSelectItemText", LazyGSelectItemText],
["GSelectLabel", LazyGSelectLabel],
["GSelectRoot", LazyGSelectRoot],
["GSelectScrollDownButton", LazyGSelectScrollDownButton],
["GSelectScrollUpButton", LazyGSelectScrollUpButton],
["GSelectSeparator", LazyGSelectSeparator],
["GSelectTrigger", LazyGSelectTrigger],
["GSelectValue", LazyGSelectValue],
["GThemeSwitcher", LazyGThemeSwitcher],
["GNavLink", LazyGNavLink],
["GNavLinkGroup", LazyGNavLinkGroup],
["GBreadcrumb", LazyGBreadcrumb],
["GBreadcrumbEllipsis", LazyGBreadcrumbEllipsis],
["GBreadcrumbItem", LazyGBreadcrumbItem],
["GBreadcrumbLink", LazyGBreadcrumbLink],
["GBreadcrumbList", LazyGBreadcrumbList],
["GBreadcrumbRoot", LazyGBreadcrumbRoot],
["GBreadcrumbSeparator", LazyGBreadcrumbSeparator],
["GTable", LazyGTable],
["GTableBody", LazyGTableBody],
["GTableCaption", LazyGTableCaption],
["GTableCell", LazyGTableCell],
["GTableEmpty", LazyGTableEmpty],
["GTableFooter", LazyGTableFooter],
["GTableHead", LazyGTableHead],
["GTableHeader", LazyGTableHeader],
["GTableLoading", LazyGTableLoading],
["GTableRoot", LazyGTableRoot],
["GTableRow", LazyGTableRow],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
