import revive_payload_client_itmCUAiq0U from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.1.2__ioredis@5.4.1__q4lkefjuuissmqbutznpt6mt2i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4lU1Y1bScK from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.1.2__ioredis@5.4.1__q4lkefjuuissmqbutznpt6mt2i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9P5TSgdvUo from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.1.2__ioredis@5.4.1__q4lkefjuuissmqbutznpt6mt2i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5NErKFsp2W from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.1.2__ioredis@5.4.1__q4lkefjuuissmqbutznpt6mt2i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_IoQNVZQo4R from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.1.2__ioredis@5.4.1__q4lkefjuuissmqbutznpt6mt2i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kZuDresRgd from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.1.2__ioredis@5.4.1__q4lkefjuuissmqbutznpt6mt2i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Spc1B4S3qV from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.1.2__ioredis@5.4.1__q4lkefjuuissmqbutznpt6mt2i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ec5zXYHbU6 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.2_vue@3.5.11_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_zDJLKKcu8b from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.1.2__ioredis@5.4.1__q4lkefjuuissmqbutznpt6mt2i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_xyy6tkAUk1 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import theme_client_BYVGIu5MsH from "/vercel/path0/node_modules/.pnpm/@una-ui+nuxt-edge@0.26.0-beta.2-28800541.492f009_@unocss+preset-wind@0.63.3_@unocss+webpack@0_y5uy4cjfdwdpc3cotdib5ejpdi/node_modules/@una-ui/nuxt-edge/dist/runtime/plugins/theme.client.js";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
export default [
  revive_payload_client_itmCUAiq0U,
  unhead_4lU1Y1bScK,
  router_9P5TSgdvUo,
  payload_client_5NErKFsp2W,
  navigation_repaint_client_IoQNVZQo4R,
  check_outdated_build_client_kZuDresRgd,
  chunk_reload_client_Spc1B4S3qV,
  plugin_vue3_ec5zXYHbU6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zDJLKKcu8b,
  plugin_client_xyy6tkAUk1,
  unocss_MzCDxu9LMj,
  theme_client_BYVGIu5MsH,
  analytics_client_mtqVw3YRXk
]